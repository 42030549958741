import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import {useDispatch} from 'react-redux'
import Container from 'src/components/helpers/Container'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'
import PropertyCard from 'src/components/modules/property/card/PropertyCard'
import {
  initPropertyState,
  setClickedPropertyUid,
  setScrollY,
} from 'src/store/actions/propertySearchActions'
import {SEARCH_RESULT_PAGE, SEARCH_RESULT_PAGE_EN} from 'src/constants/route'
import {useFavoritePropertyIds} from 'src/hooks/property'
import {PropertyCardProps} from 'src/types/property'
import {LOCALE_EN} from 'src/constants/locale'
import {useCurrentLocale} from 'src/hooks/locale'
import {useStyles} from './styles'
import {Fragment} from 'react'

export default function Properties(props: {properties: PropertyCardProps[]}) {
  const {properties} = props

  const classes = useStyles()
  const {t} = useTranslation('home')
  const router = useRouter()
  const dispatch = useDispatch()
  const currentLocale = useCurrentLocale()
  const searchPage =
    currentLocale === LOCALE_EN ? SEARCH_RESULT_PAGE_EN : SEARCH_RESULT_PAGE

  const gotoAllProperties = () => {
    dispatch(initPropertyState())
    dispatch(setScrollY(0))
    dispatch(setClickedPropertyUid(null))

    router.push(searchPage)
  }

  const {favoritePropertyIds} = useFavoritePropertyIds()

  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Fragment>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {properties.map((data: PropertyCardProps, index: number) => {
              const isFavorite: boolean =
                favoritePropertyIds.get(data.id) || false

              /* preload image swiper only for first 2 rows  */

              return (
                <PropertyCard
                  key={data.id}
                  preloadImageSlider={index <= 6}
                  data={data}
                  isFavorite={isFavorite}
                />
              )
            })}
          </div>
          <AppRoundedButton
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={gotoAllProperties}
          >
            {t('see_all')}
          </AppRoundedButton>
        </Fragment>
      </Container>
    </div>
  )
}
