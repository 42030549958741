import {useSmallSize} from 'src/hooks/screenSize'
import InfoBoxesDesktop from './InfoBoxesDesktop'
import Container from 'src/components/helpers/Container'

export default function Info() {
  const isSmallSize: boolean = useSmallSize()

  const COMP = isSmallSize ? InfoBoxesDesktop : InfoBoxesDesktop

  return (
    <Container>
      <COMP />
    </Container>
  )
}
