import {useTranslation} from 'next-i18next'
import Container from 'src/components/helpers/Container'
import AppTypography from 'src/components/elements/typography/AppTypography'
import DiscoverCard from 'src/components/modules/discoverCard/DiscoverCard'
import {useSmallSize} from 'src/hooks/screenSize'
import {useCurrentLocale} from 'src/hooks/locale'
import {LOCALE_EN} from 'src/constants/locale'
import {properties} from './data'
import {useStyles} from './styles'

export default function DiscoverProperties() {
  const {t} = useTranslation('common')

  const classes = useStyles()
  const isSmallSize = useSmallSize()
  const currentLocale = useCurrentLocale()

  return (
    <div className={classes.sliderWrap}>
      <div className={classes.background}>
        <Container maxWidth="xl">
          <div className={classes.container}>
            <AppTypography
              variant={isSmallSize ? 'displayLarge' : 'displayXLarge'}
              neutralColor={900}
              className={classes.title}
              component="h2"
            >
              {t('discover_regions')}
            </AppTypography>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {properties.map((property, index) => {
                return (
                  <a
                    className="block"
                    key={index}
                    href={
                      currentLocale === LOCALE_EN
                        ? property.link_en
                        : property.link
                    }
                  >
                    <DiscoverCard
                      label_en={property.label_en}
                      label_fr={property.label_fr}
                      description_en={property.description_en}
                      description_fr={property.description_fr}
                      className={classes.image}
                      classes={classes}
                      image={property.image}
                    />
                  </a>
                )
              })}
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
